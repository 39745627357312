<template>
    <div id="Hotels">
        <div class="text-center">
            <router-link
                tag="button"
                v-for="hotel in hotels"
                :key="hotel.id"
                :to="{name: 'Hotel', params:{id: hotel.id,hotel: hotel}}"
                class="btn btn-secondary press text-white font-weight-bold mx-3 my-3"
            >
                {{hotel.name}}
            </router-link>
        </div>
    </div>
</template>

<script>
import {hotelData} from '@/hotelsData'
export default {
    name: "Hotels",
    data() {
        return {
            hotels:hotelData
        }
    },
    mounted() {

    },
    methods: {}
}
</script>

<style lang="scss" scoped>

</style>
