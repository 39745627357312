<template>
    <div id="Slider" class="">
        <VueSlickCarousel
            id="offers-slider"
            :arrows="false"
            :autoplay="true"
            :autoplaySpeed="5000"
            :draggable="false"
            :fade="true"
        >
            <div
                :key="slide.id"
                v-for="slide in slides"
            >
                <img :src="slide.img" class="img-fluid">
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: "Slider",
    components: { VueSlickCarousel },

    data() {
        return {
            slides:[
                {
                    id: 1,
                    img: require('../assets/images/slider-img-1.png')
                },
                {
                    id: 2,
                    img: require('../assets/images/slider-img-2.png')
                },
                {
                    id: 3,
                    img: require('../assets/images/slider-img-3.png')
                },
                {
                    id: 4,
                    img: require('../assets/images/slider-img-4.png')
                },
            ]
        }
    },
    mounted() {

    },
    methods: {}
}
</script>

<style lang="scss" >
#Slider{
    img{
        max-height: 300px;
        display: block;
        margin: auto;
    }
}
</style>
