import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Hotel from "@/components/Hotel";
import Offers from "@/views/Offers";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home,
        children: [
            {
                path: '',
                name: 'Offers',
                component: Offers,
            },
            {
                path: 'hotel/:id',
                name: 'Hotel',
                component: Hotel,
                props: true
            },


        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
