<template>
    <div id="Logo">
        <img
            src="../assets/logo.png"
            alt="Logo"
            class="img-fluid d-block mx-auto"
        >
    </div>
</template>

<script>
export default {
    name: "Logo",
    data(){
        return{

        }
    },
    mounted() {

    },
    methods:{

    }
}
</script>

<style lang="scss" scoped>
#Logo{
    img{
        width: 200px;
    }
}
</style>
