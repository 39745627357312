export const hotelData = [
    {
        id: 1,
        name: 'فندق الأزهر جدة',
        phone1: '0540304739',
        phone2: '0545112784',
        whatsapp1: '0540304739',
        whatsapp2: '0545112784',
        facebook: 'https://www.facebook.com/alazharhotelj/',
        twitter: null,
        snapchat: 'https://www.snapchat.com/add/alazharhotels',
        instagram: 'https://instagram.com/alazharhotelj',
        img: 'https://cf.bstatic.com/images/hotel/max1024x768/151/151187335.jpg',
        map: 'https://www.google.com/maps/place/%D9%81%D9%86%D8%AF%D9%82+%D8%A7%D9%84%D8%A3%D8%B2%D9%87%D8%B1+%D8%AC%D8%AF%D9%87+Al+Azhar+Hotel+Jeddah%E2%80%AD/@21.493988,39.1859913,18z/data=!4m18!1m9!3m8!1s0x15c3cf00351bc6ad:0x411039c62d0bfd2f!2z2YHZhtiv2YIg2KfZhNij2LLZh9ixINis2K_ZhyBBbCBBemhhciBIb3RlbCBKZWRkYWg!5m2!4m1!1i2!8m2!3d21.4950973!4d39.1850619!3m7!1s0x15c3cf00351bc6ad:0x411039c62d0bfd2f!5m2!4m1!1i2!8m2!3d21.4950973!4d39.1850619?hl=ar',
        address: 'طريق المدينة المنورة، البغدادية الغربية، جدة 24770، المملكة العربية السعودية',

    },
    {
        id: 2,
        name: 'فندق روشان الأزهر',
        phone1: '0549284090',
        phone2: '0569598067',
        whatsapp1: '0549284090',
        whatsapp2: '0569598067',
        facebook: 'https://www.facebook.com/alazharhotelj/',
        twitter: null,
        snapchat: 'https://www.snapchat.com/add/roshan.hotel',
        instagram: 'https://instagram.com/roshan_hotel',
        img: 'https://cf.bstatic.com/images/hotel/max1024x768/200/200127743.jpg',
        map: 'https://www.google.com/maps/place/%D9%81%D9%86%D8%AF%D9%82+%D8%B1%D9%88%D8%B4%D8%A7%D9%86+%D8%A7%D9%84%D8%A7%D8%B2%D9%87%D8%B1%E2%80%AD/@21.5638651,39.1862005,19z/data=!4m8!3m7!1s0x15c3d1051ab133c9:0x67a76adcd8df834e!5m2!4m1!1i2!8m2!3d21.5636312!4d39.1858855?hl=ar',
        address: 'طريق الملك فهد، الفيصلية، جدة 23441، المملكة العربية السعودية',
    },
]
