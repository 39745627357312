<template>
  <div id="home">
      <div class="container">
          <Logo/>
          <h5 class="font-weight-bolder text-secondary text-center my-4" >ترقبو افتتاح الموقع الرسمي</h5>

          <CountDown />

          <router-view/>

      </div>
  </div>
</template>

<script>

import Logo from "@/components/Logo";
import CountDown from "@/components/CountDown";
import Slider from "@/components/Slider";
export default {
  name: 'Home',
  components: {
      Slider,
      CountDown,
      Logo
  },
    data(){
      return{
          showSlider: true,

      }
    },
    methods:{

    }
}
</script>
