<template>
    <div id="Offers" class="d-flex flex-column justify-content-around align-content-around ">
        <Slider/>
        <Hotels class="mt-5"/>
    </div>
</template>

<script>
import Slider from "@/components/Slider";
import Hotels from "@/components/Hotels";
export default {
    name: "Offers",
    components: {Hotels, Slider},
    data() {
        return {}
    },
    mounted() {

    },
    methods: {}
}
</script>

<style lang="scss" scoped>

</style>
