<template>
    <div id="CountDown">

        <div id="app">
            <div id="clockdiv" class="text-center text-white h3 my-4 font-weight-lighter">
                <div class="d-flex  flex-row justify-content-center align-items-center ">
                    <div class="d-flex flex-column mx-md-4 mx-2">
                        <span class="font-weight-bold">
                            {{timer.days}}
                        </span>
                        <span class="small h6 font-weight-light">
                            Days
                        </span>
                    </div>
                    <div class="d-flex flex-column mx-md-4 mx-2">
                        <span class="font-weight-bold">
                            {{timer.hours}}
                        </span>
                        <span class="small h6 font-weight-light">
                            Hours
                        </span>
                    </div>
                    <div class="d-flex flex-column mx-md-4 mx-2">
                        <span class="font-weight-bold">
                            {{timer.minutes}}
                        </span>
                        <span class="small h6 font-weight-light">
                            Minutes
                        </span>
                    </div>
                    <div class="d-flex flex-column mx-md-4 mx-2">
                        <span class="font-weight-bold">
                            {{timer.seconds}}
                        </span>
                        <span class="small h6 font-weight-light">
                            Seconds
                        </span>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "CountDown",
    data() {
        return {
            deadline: 'April 15 2021 7:0:0 GMT+0200',
            numberOfTimers: 3,
            timer: {
                total: 0,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            }
        }
    },
    mounted() {
        this.initializeClock(this.deadline);

    },
    methods: {

        initializeClock(endtime){
            let timeinterval = setInterval(()=>{
                let result = this.getTimeRemaining(endtime);

                this.timer = {
                    total: result.totalRemaining,
                    days: result.daysRemaining,

                    hours: result.hoursRemaining,
                    minutes: result.minutesRemaining,
                    seconds: result.secondsRemaining
                }

                if(result.total<=0){
                    clearInterval(timeinterval);
                }
            },1000);
        },

        getTimeRemaining(endtime){
            let t = Date.parse(endtime) - Date.parse(new Date());
            let secondsRemaining = Math.floor( (t/1000) % 60 );
            let minutesRemaining = Math.floor( (t/1000/60) % 60 );
            let hoursRemaining = Math.floor( (t/(1000*60*60)) % 24 );
            let daysRemaining = Math.floor( t/(1000*60*60*24) );
            return {
                'totalRemaining': t,
                daysRemaining,
                hoursRemaining,
                minutesRemaining,
                secondsRemaining
            };
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
